import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";
import FraventsBackground from "../../../assets/images/dummy/sosial_background.jpeg";

const styles = makeStyles({
  container: {
    position: "relative",
    backgroundColor: colors.black,
    width: "100%",
    height: "100%",
    overflow: "auto",
    maxHeight: "100vh",
    maxWidth: "100vw",
    ...Commons.coverBackground,
    backgroundImage: `url("${FraventsBackground}")`,
    backgroundPosition: "right top",
    [Commons.smallQuery]: {
      backgroundSize: "contain",
    },
  },
  background: {
    position: "fixed",
    width: "100vw",
    bottom: 0,
    right: 0,
    objectFit: "cover",
    objectPosition: "right top",
    zIndex: 0,
    [Commons.smallQuery]: {
      bottom: "unset",
      top: 0,
      right: 0,
    },
  },
  scrollContainer: {
    position: "relative",
    zIndex: 0,
    padding: "12px 30px 0 18px",
    maxWidth: 420,
    margin: "30vh auto 0 auto",
    backgroundColor: `${colors.black}75`,
    borderRadius: "30px 30px 0 0",
    [Commons.smallQuery]: {
      backgroundImage: "linear-gradient(transparent 0.01%, black)",
      backgroundColor: colors.black,
      margin: "calc(56vw - 18px) auto 0 auto",
    },
  },
  title: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    color: colors.white,
    backgroundColor: colors.black,
    width: "fit-content",
    margin: "0 auto 24px auto",
    borderRadius: 18,
    padding: "3px 18px",
  },
  iconSocial: {
    ...Commons.defaultButton,
    "& svg": {
      color: colors.white,
      fontSize: 42,
      backgroundColor: colors.black,
      borderRadius: 24,
      padding: 6,
      border: `2px solid ${colors.white}50`,
    },
  },
  buttonLink: {
    ...Commons.defaultButton,
    textAlign: "center",
    color: colors.white,
    backgroundColor: colors.themeColor,
    width: "100%",
    borderRadius: 30,
    padding: "12px 6px",
    marginBottom: 24,
    fontSize: 18,
    fontWeight: 600,
    boxShadow: `0px 1px 6px 5px ${colors.themeColor}50`,
  },
  mainEvent: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    padding: 12,
    backgroundColor: colors.white,
    borderRadius: 12,
    boxShadow: `0px 1px 6px 5px ${colors.themeColor}50`,
    gap: 12,
    marginBottom: 24,
    border: `3px solid ${colors.themeColor}`,
  },
  imageMainEvent: {
    width: "30%",
    borderRadius: 6,
    boxShadow: colors.shadow,
    minWidth: 60,
    minHeight: 60,
  },
  infoMainEvent: {
    flex: 2,
  },
  dateMainEvent: {
    margin: 0,
    color: colors.themeColor,
    textAlign: "right",
    fontWeight: 700,
    fontSize: 12,
    marginTop: 12,
  },
  titleMainEvent: {
    fontSize: 24,
    fontWeight: 700,
    margin: "0 0 12px 0",
    lineHeight: "22px",
    textAlign: "left",
    color: colors.black,
  },
  descriptionMainEvent: {
    fontSize: 12,
    margin: 0,
    textAlign: "left",
    color: colors.black,
  },
  footer: {
    ...Commons.flexCenter,
    backgroundImage: `linear-gradient(transparent 0.01%, ${colors.themeColor}, ${colors.themeColor})`,
    padding: 18,
    fontWeight: 600,
    textAlign: "center",
    color: colors.white,
    borderRadius: "18px 18px 0 0",
    width: "calc(100% + 12px)",
    marginLeft: -18,
    marginTop: 24,
  },
});

export default styles;
