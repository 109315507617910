import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation, Trans } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import moment from "moment";

import { renderPricePoster, renderEllipsis } from "../../utils/validations";
import BannerHome from "../../components/BannerHome";
import ItemEvent from "../../components/ItemEvent";
import EventsService from "../../services/events";
import pageStyles from "./styles/dashboardStyles";
import Features from "../../components/Features";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const Dashboard = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);

  const returnEvents = (pastEvents = false) =>
    state.events.filter(
      (item) =>
        item.status === "published" &&
        (pastEvents
          ? parseInt(item.date) + 8640000 < parseInt(moment().format("x"))
          : parseInt(item.date) + 8640000 > parseInt(moment().format("x")))
    );

  const onSelectEvent = (item) => {
    Model.setData("eventSelected", item);
    history.push("/events");
  };

  const renderRowItems = () => {
    return state?.systemSettings?.eventsBanner
      .map(
        (item) =>
          state.events[
            state.events.findIndex((itemInfo) => itemInfo.uid === item)
          ]
      )
      .filter((item) => !!item)
      .map((item, index) => (
        <div
          className={styles.itemCardEventImage}
          style={{ backgroundImage: `url(${item?.imageBanner})` }}
          key={"item-row-event-" + index}
          onClick={() => onSelectEvent(item)}
        >
          <div className={styles.bgDropItemCardEvent} />
          <div
            className={styles.imageItemCardEvent}
            style={{ backgroundImage: `url(${item?.imageBanner})` }}
          >
            <div className={styles.badgePrice}>
              {renderPricePoster(item?.prices, item.acceptUsd ? "USD" : "COP")}
            </div>
          </div>
          <div className={styles.containerInfoItemCardEvent}>
            <div className={styles.rowHeaderItemCardEvent}>
              <div>
                {renderEllipsis(
                  `${moment(item.date, "x").format("DD MMM, YYYY - ha")} | ${
                    item.location
                  }`,
                  50
                )}
              </div>
            </div>
            <div className={styles.borderInfoItemCardEvent}>
              <p className={styles.nameItemCardEvent}>
                {renderEllipsis(item?.name, 60)}
              </p>
              <div className={styles.descriptionItemCardEvent}>
                {renderEllipsis(item?.description, 240)}
              </div>
            </div>
            <div className={styles.viewMoreItemCardEvent}>
              {t("pages.dashboard.index.viewMoreInfo")}
            </div>
          </div>
        </div>
      ));
  };

  // const renderCategories = () => {
  //   return vc.categories.map((item, index) => <div
  //     className={styles.itemCategory}
  //     key={'item-category-' + index}
  //   >
  //     <div className={styles.imgCategory} />
  //     <div className={styles.lblCategory}>Talleres</div>
  //   </div>)
  // }

  const renderEvents = (pastEvents = false) => {
    const dataToRender = returnEvents(pastEvents);
    if (dataToRender.length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.dashboard.index.thereNoEvents", {
            pastEvents: pastEvents
              ? t("pages.dashboard.index.before")
              : t("pages.dashboard.index.closed"),
          })}
        </div>
      );
    }
    return dataToRender
      .sort((a, b) => (pastEvents ? b.date - a.date : a.date - b.date))
      .slice(0, 10)
      .map((item, index) => (
        <ItemEvent
          key={"item-list-event-" + index}
          item={item}
          onClick={onSelectEvent}
          callback={getEvents}
        />
      ));
  };

  const getEvents = async () => {
    try {
      if (!state.events?.length) {
        Model.setData("loading", true);
      }
      await EventsService.getEvents();
    } catch (e) {
      console.log(t("pages.dashboard.index.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    Model.setData("isWhiteLabel", false);
    Model.setData("infoPartner", {});
    setTimeout(getEvents, 500);
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <BannerHome />

      <div className={styles.responsiveContent}>
        <h3 className={styles.title}>
          {t("pages.dashboard.index.whatYouCanDo")}
        </h3>
        <p className={styles.subtitle}>
          <Trans
            i18nKey="pages.dashboard.index.weOfferFeatures"
            components={{ bold: <b /> }}
          />{" "}
          <span role="img" aria-label="nerd">
            🤓
          </span>
        </p>

        <Features />

        {state?.systemSettings?.eventsBanner?.length > 0 ? (
          <>
            <h3 className={styles.title}>
              {t("pages.dashboard.index.featured")}
            </h3>
            <p className={styles.subtitle}>
              {t("pages.dashboard.index.eventOnFire")}{" "}
              <span role="img" aria-label="fire">
                🔥
              </span>
            </p>
            <Carousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showThumbs={false}
              className={styles.contCarousel}
            >
              <div
                className={styles.itemCardEvent}
                onClick={() => history.push("contactPublish")}
              >
                <h5 className={styles.txtCtaBanner}>
                  <Trans
                    i18nKey="pages.dashboard.index.meetOutBenefits"
                    components={{ bold: <b /> }}
                  />
                </h5>
                <div className={styles.btnCtaList}>
                  {t("pages.dashboard.index.viewMoreInfo")}
                </div>
              </div>
              {renderRowItems()}
            </Carousel>
            {/* <p className={styles.labelRow}>Categorias</p>
        <div className={styles.rowItems}>
          {renderCategories()}
        </div> */}
          </>
        ) : null}

        {returnEvents().length > 2 ? (
          <>
            <div className={styles.rowInfo}>
              <div className={styles.labelInfo}>
                <h3 className={styles.title}>
                  {t("pages.dashboard.index.soon")}
                </h3>
                <p className={styles.subtitle}>
                  {t("pages.dashboard.index.noKeepWithoutPlan")}{" "}
                  <span role="img" aria-label="party">
                    🥳
                  </span>
                </p>
              </div>
              <Link className={styles.viewAllBtn} to={Routes.ALL_EVENTS}>
                {t("pages.dashboard.index.viewAll")}
              </Link>
            </div>
            <div className={styles.containerCarousel}>
              <div className={styles.leftShadowCarousel} />
              <div className={styles.listOfEvents}>
                <div className={styles.spaceShadowCarousel} />
                {renderEvents()}
                <div className={styles.spaceShadowCarousel} />
                <div
                  className={styles.ctaList}
                  onClick={() =>
                    history.push(
                      state?.userInfo?.isPartner
                        ? "/eventForm"
                        : "/contactPublish"
                    )
                  }
                >
                  <p className={styles.txtCtaList}>
                    {t("pages.dashboard.index.doItEasy")}
                  </p>
                  <div className={styles.btnCtaList}>
                    {t("pages.dashboard.index.createYourEvent")}
                  </div>
                </div>
              </div>
              <div className={styles.rightShadowCarousel} />
            </div>
          </>
        ) : null}

        <h3 className={styles.title}>{t("pages.dashboard.index.befored")}</h3>
        <p className={styles.subtitle}>
          {t("pages.dashboard.index.thanksEvents")}{" "}
          <span role="img" aria-label="fan">
            🤩
          </span>
        </p>
        <div className={styles.containerCarousel}>
          <div className={styles.leftShadowCarousel} />
          <div className={styles.listOfEvents}>
            <div className={styles.spaceShadowCarousel} />
            {renderEvents(true)}
            <div className={styles.spaceShadowCarousel} />
          </div>
          <div className={styles.rightShadowCarousel} />
        </div>

        <div className={styles.blank} />
      </div>
    </Layout>
  );
};

export default Dashboard;
