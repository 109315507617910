import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Close } from "@material-ui/icons";

import { commonPlatformTax, commonMpTax } from "../../utils/constants";
import { currencyFormat } from "../../utils/validations";
import pageStyles from "./styles/selectQuantityStyles";
import { Store } from "../../hooks/main_store";
import Model from "../../hooks/Model";

const SelectQuantity = ({
  onClose = () => {},
  onDone = () => {},
  data = {},
  show = false,
  isReferred = false,
}) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [vc, setVc] = React.useState({
    types: data?.prices || [],
    quantity: "1",
    showError: "",
    typeSlctd: null,
    paymentSelected: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const renderPrice = (price) => {
    if (price <= 0) {
      return t("commons.free");
    } else if (price <= 999) {
      return `$ ${price}`;
    } else if (Math.floor(price / 1000) <= 999) {
      return `$ ${Math.floor(price / 1000)}k`;
    } else {
      return `$ ${Math.floor(price / 1000000)}M`;
    }
  };

  const isCurrentPhase = (index) => {
    let currentPhase = -1;

    if (vc.types.length < 2) {
      return true;
    }

    vc.types.forEach((price, indexPrice) => {
      if (price?.stock > 0) {
        if (currentPhase === -1) {
          currentPhase = indexPrice;
        }
      }
    });

    return index === currentPhase;
  };

  const renderTypes = () =>
    vc.types?.map((type, index) => {
      const availablePhase = data?.saleWithPhases
        ? isCurrentPhase(index) || type?.forceAvailable
        : true;
      const isFreeReferred = type?.price === 0 && isReferred;
      const disabled =
        (!type.stock || type.stock < 1 || !availablePhase) && !isFreeReferred;

      const returnSublabel = () => {
        if (type.stock < 1) {
          return (
            <span className={styles.fewTickets}>
              {t("pages.events.selectQuantity.soldOut")}
            </span>
          );
        }
        if (data?.saleWithPhases && !availablePhase && !isFreeReferred) {
          return (
            <span className={styles.fewTickets}>
              {t("pages.events.selectQuantity.soon")}
            </span>
          );
        }
        if (type.stock < 11 && type.stock > 0) {
          return (
            <span
              className={[
                styles.fewTickets,
                vc.typeSlctd === index ? styles.typeSlctd : "",
              ].join(" ")}
            >
              {type.stock} {t("pages.events.selectQuantity.available")}
            </span>
          );
        }
      };

      return (
        <button
          className={[
            styles.btnType,
            vc.typeSlctd === index ? styles.typeSlctd : "",
            disabled ? styles.disabled : "",
            type?.hide && !type?.forceAvailable ? styles.noDisplay : "",
          ].join(" ")}
          onClick={() => {
            changeVc({ showError: "" });
            if (!disabled) {
              changeVc({ typeSlctd: index, quantity: "1" });
            }
          }}
          key={"item-type-" + index}
          disabled={disabled}
        >
          <span>{type.label}</span>
          <span>
            {renderPrice(
              (state.defaultCurrency === "USD" &&
                data?.acceptUsd &&
                data?.acceptCop) ||
                (data?.acceptUsd && !data?.acceptCop)
                ? type.usdPrice || 0
                : type.price || 0
            )}
          </span>
          {returnSublabel()}
        </button>
      );
    });

  const changeQuantity = (event) => {
    if (vc.typeSlctd === null) {
      return Model.updateAlerts({
        message: t("pages.events.selectQuantity.firstSelect"),
        variant: "error",
      });
    }
    let newValue = parseInt(vc.quantity);
    changeVc({ showError: "" });
    if (!event || (event?.target && !event?.target?.value)) {
      changeVc({ quantity: "" });
    } else {
      if (typeof event === "number") {
        newValue = newValue + event;
      } else if (event?.target?.value) {
        newValue = parseInt(event.target.value.replace(/[^0-9]/g, ""));
      }
      if (newValue <= 0) {
        newValue = 1;
      } else if (newValue > vc.types[vc.typeSlctd].stock) {
        newValue =
          vc.types[vc.typeSlctd].stock > 10 ? 10 : vc.types[vc.typeSlctd].stock;
        changeVc({
          showError: t("pages.events.selectQuantity.noMoreQuantity"),
        });
      } else if (newValue > 10) {
        newValue = 10;
        changeVc({
          showError: t("pages.events.selectQuantity.alertMaxQuantity"),
        });
      }
      changeVc({ quantity: String(newValue) });
    }
  };

  const renderTotal = (typePrice) => {
    const quantity = parseInt(vc.quantity) || 0;
    const price = vc.types?.[vc.typeSlctd]?.[typePrice] || 0;
    return currencyFormat(
      quantity * price + (data?.clientPayTax ? returnTax(typePrice) : 0),
      "COP",
      typePrice === "price" ? 0 : 2
    );
  };

  const returnTax = (typePrice) => {
    const preferentialRate = data?.organizer?.preferentialRate
      ? parseFloat(data?.organizer?.preferentialRate) / 100
      : commonPlatformTax;
    const quantity = parseInt(vc.quantity) || 0;
    const price = vc.types?.[vc.typeSlctd]?.[typePrice] || 0;
    const platformTax =
      quantity * price * (typePrice === "price" ? preferentialRate : 0.03);
    // quantity * price * (parseFloat(data?.organizer?.customRate) || commonPlatformTax);
    const providerTax =
      typePrice === "price"
        ? quantity * price * commonMpTax[0] + commonMpTax[1]
        : quantity * price * 0.02;

    if (!data?.clientPayTax || quantity < 1 || vc.typeSlctd === null) {
      return 0;
    }

    return platformTax + providerTax;
  };

  const onChangePaymentMethod = (newMethod) => {
    const newCurrency = newMethod === "cryptomus" ? "USD" : "COP";

    if (newCurrency !== state.defaultCurrency) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.events.selectQuantity.changeCurrency"),
        text: t("pages.events.selectQuantity.selectOtherCurrency", {
          newCurrency,
          defaultCurrency: state.defaultCurrency,
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => {
          Model.setData("dialog", { open: false });
        },
        txtRight: t("commons.yes"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          Model.setData("defaultCurrency", newCurrency);
        },
      });
    }
  };

  React.useEffect(() => {
    if (!show) {
      changeVc({
        quantity: "1",
        showError: "",
        typeSlctd: null,
      });
    }
    //eslint-disable-next-line
  }, [show]);

  React.useEffect(() => {
    changeVc({
      paymentSelected:
        state.defaultCurrency === "USD" &&
        data?.acceptUsd &&
        data?.organizer?.bitcoin
          ? "cryptomus"
          : state.defaultCurrency === "COP" &&
            data?.acceptCop &&
            data?.organizer?.secret
          ? "mercadopago"
          : "",
    });
    //eslint-disable-next-line
  }, [data, state.defaultCurrency]);

  React.useEffect(() => {
    changeVc({
      types: data?.prices || [],
    });
    //eslint-disable-next-line
  }, [data]);
  let showMercadopagoButton =
    vc.types?.[vc.typeSlctd]?.price &&
    data?.acceptCop &&
    data?.organizer?.secret &&
    !isReferred;
  let showBitcoinButton =
    vc.types?.[vc.typeSlctd]?.usdPrice &&
    data?.acceptUsd &&
    data?.organizer?.bitcoin &&
    !isReferred;

  return show ? (
    <div className={styles.container}>
      <button className={styles.closeBtn} onClick={onClose}>
        <Close />
      </button>
      <div className={styles.contForm}>
        <p className={styles.label}>
          {t("pages.events.selectQuantity.typeTicket")}
        </p>
        <div className={[styles.rowTypes, styles.centerTypes].join(" ")}>
          {renderTypes()}
        </div>
        {vc.types?.[vc.typeSlctd]?.price ||
        vc.types?.[vc.typeSlctd]?.usdPrice ? (
          <p className={styles.sublabelPrices}>
            {data?.clientPayTax
              ? t("pages.events.selectQuantity.taxBuy", {
                  value: currencyFormat(
                    returnTax(
                      state.defaultCurrency === "USD" ? "usdPrice" : "price"
                    ),
                    "COP",
                    state.defaultCurrency === "USD" ? 2 : 0
                  ),
                })
              : t("pages.events.selectQuantity.taxIncluded")}
          </p>
        ) : null}
        <p className={styles.label}>
          {t("pages.events.selectQuantity.quantity")}
        </p>
        <div className={styles.rowQuantity}>
          <button
            className={styles.changeQuantityBtn}
            onClick={() => changeQuantity(-1)}
          >
            -
          </button>
          <input
            className={styles.inputQuantity}
            value={vc.quantity}
            onChange={changeQuantity}
          />
          <button
            className={styles.changeQuantityBtn}
            onClick={() => changeQuantity(1)}
          >
            +
          </button>
        </div>
        <p className={styles.labelError}>{vc.showError}</p>
        {(vc.types?.[vc.typeSlctd]?.price ||
          vc.types?.[vc.typeSlctd]?.usdPrice) &&
        !isReferred ? (
          <p className={styles.label}>
            {t("pages.events.selectQuantity.totalPrice")}
          </p>
        ) : null}
        <div className={styles.price}>
          {showMercadopagoButton ? (
            <button
              className={[
                styles.paymentButton,
                vc.paymentSelected === "mercadopago" ? "selected" : "",
              ].join(" ")}
              onClick={() => onChangePaymentMethod("mercadopago")}
            >
              <div className={styles.rowLabelPayementButton}>
                <span className={styles.labelPaymentButton}>
                  <Trans
                    i18nKey="pages.events.selectQuantity.withLocalPayment"
                    components={{ bold: <b /> }}
                  />
                </span>
                <img
                  alt="mercadopago"
                  src={require("../../assets/images/providers/mercadopago.png")}
                />
              </div>
              <p className={styles.amountPaymentButton}>
                {renderTotal("price")} COP
              </p>
            </button>
          ) : null}
          {showBitcoinButton ? (
            <button
              className={[
                styles.paymentButton,
                vc.paymentSelected === "cryptomus" ? "selected" : "",
              ].join(" ")}
              onClick={() => onChangePaymentMethod("cryptomus")}
            >
              <div className={styles.rowLabelPayementButton}>
                <span className={styles.labelPaymentButton}>
                  <Trans
                    i18nKey="pages.events.selectQuantity.withCryptosPayment"
                    components={{ bold: <b /> }}
                  />
                </span>
                <img
                  alt="bitcoint"
                  src={require("../../assets/images/providers/bitcoin.png")}
                />
              </div>
              <p className={styles.amountPaymentButton}>
                {renderTotal("usdPrice")} USD
              </p>
            </button>
          ) : null}
          {vc.typeSlctd !== null &&
          !showMercadopagoButton &&
          !showBitcoinButton &&
          !isReferred &&
          !(
            vc.types?.[vc.typeSlctd]?.price === 0 ||
            vc.types?.[vc.typeSlctd]?.usdPrice === 0
          ) ? (
            <span>{t("pages.events.selectQuantity.errorpartnerAccounts")}</span>
          ) : null}
        </div>
        <button
          className={[
            styles.payBtn,
            vc.paymentSelected ||
            vc.types?.[vc.typeSlctd]?.price === 0 ||
            // vc.types?.[vc.typeSlctd]?.usdPrice === 0) ||
            isReferred
              ? ""
              : styles.disabled,
          ].join(" ")}
          onClick={() =>
            vc.paymentSelected ||
            vc.types?.[vc.typeSlctd]?.price === 0 ||
            // vc.types?.[vc.typeSlctd]?.usdPrice === 0) ||
            isReferred
              ? onDone(vc.typeSlctd, vc.quantity)
              : null
          }
        >
          {vc.paymentSelected ||
          vc.types?.[vc.typeSlctd]?.price === 0 ||
          // vc.types?.[vc.typeSlctd]?.usdPrice === 0) ||
          isReferred
            ? t("pages.events.selectQuantity.continuePayment")
            : t("pages.events.selectQuantity.remainData")}
        </button>
      </div>
    </div>
  ) : null;
};

export default SelectQuantity;
