export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const PROFILE = "/profile";
export const ALL_EVENTS = "/allEvents";
export const EVENTS = "/events";
export const FINISH_PAYMENT = "/finishPayment";
export const DETAIL_TICKETS = "/detailTickets";
export const MY_TRANSACTIONS = "/myTransactions";
export const CONTACT_PUBLISH = "/contactPublish";
export const VIDEO_TUTORIAL = "/videoTutorial";
export const ADMIN = "/admin";
export const TEAM = "/team";
export const SALES = "/sales";
export const VALIDATE_TICKET = "/validate-ticket";
export const EVENT_FORM = "/eventForm";
export const CONFIRM_PARTNER = "/confirmPartner";
export const DETAIL_MEMBER = "/detailMember";
export const PARTNER_PROFILE = "/partnerProfile";
export const PLUGIN_PARTNER = "/pluginPartner";
export const INFO_PLUGIN = "/infoPlugin";
export const FAQ = "/faq";
export const DETAIL_PRODUCT = "/detail-product";
export const PRODUCT_FORM = "/productForm";
export const SHOPPING_CART = "/shoppingCart";
export const SELL_DETAIL = "/sellDetail";
export const ADMIN_SHOP = "/admin-shop";
export const SHOP_REPORTS = "/shop-reports";
export const PROGRAM_INFO = "/program-info";
export const RENDER_LEGAL_DOC = "/legal-doc";
export const COMPANY_SOCIAL_MEDIA_LINKS = "/company-social-media-links";
